/* You can add global styles to this file, and also import other style files */
@use '@angular/material/theming' as mat-theming;

$custom-typography: mat-theming.mat-typography-config(
  $headline: mat-theming.mat-typography-level(32px, 48px, 700),
  $body-1: mat-theming.mat-typography-level(13px, 16px, 500),
  $button: mat-theming.mat-typography-level(13px, 16px, 500),
);
@include mat-theming.angular-material-typography($custom-typography);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  background: #e9ecef;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  overflow: hidden;
}

/** Custom Scrollbar*/
::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 24px #0000005e;
  border-radius: 24px;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
  margin: 0;
}

// Due to the specificity of the default CSS of angular-material being equivalent to the CSS specified in the component,
// the styling can be inconsistently applied depending on the loading timing.
// To avoid this, we increased specificity using table-container.
.table-container {
  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: 24px;
  }
}

/*
From "@angular/flex-layout"
Change area to CSS Flexbox From here
*/

/* If the flex specifications overlap, the design will collapse, so separate the flex parts so that they do not overlap. */
.-flex {
  display: flex;
}

/* fxLayout="row" -> class="flex-row" */
.flex-row {
  flex-direction: row;
}

/* fxLayout="column" -> class="flex-column" */
.flex-column {
  flex-direction: column;
}

/* fxLayoutAlign="space-around" */
.justify-space-around {
  justify-content: space-around;
}

/* fxLayoutAlign="space-between" */
.justify-space-between {
  justify-content: space-between;
}

/* fxLayoutAlign="center center" */
.align-center-center {
  align-items: center;
  justify-content: center;
}

/* fxlayoutalign="start stretch" */
.align-start-stretch {
  align-items: stretch;
  justify-content: flex-start;
}

/* fxLayoutAlign="start start" */
.align-start-start {
  align-items: flex-start;
  justify-content: flex-start;
}

/* fxLayoutAlign="start center" */
.align-start-center {
  align-items: center;
  justify-content: flex-start;
}

/* fxFlex="grow" */
.flex-grow {
  flex-grow: 1;
}

/* fxLayout="row wrap" */
.flex-row-wrap {
  flex-direction: row;
  flex-wrap: wrap;
}

/* fxLayoutGap="calc(1% / 2)" */
.layout-gap-half-percent {
  gap: calc(1% / 2);
}

/* fxLayoutGap="1%" */
.flex-gap-1-percent > * + * {
  margin-top: 1%;
}

/* fxLayoutGap="5px" */
.flex-gap-5px > * + * {
  margin-top: 5px;
}

/* fxLayoutGap="20px" */
.flex-gap-20px > * + * {
  margin-left: 20px;
}

/* fxFlex="1 0 auto" */
.flex-1-0-auto {
  flex: 1 0 auto;
}

/* fxFlex="0 1 auto" */
.flex-0-1-auto {
  flex: 0 1 auto;
}

/* fxFlex="1 0 50" */
.flex-1-0-50 {
  flex: 1 0 50%;
}

/* fxFlex="15" */
.flex-15 {
  flex: 0 0 15%;
}

/* fxFlex="18%" */
.flex-18 {
  flex: 0 0 18%;
}

/* fxFlex="0 0 20"
fxFlex="20%"
 */
.flex-20 {
  flex: 0 0 20%;
}

/* fxFlex="33%" */
.flex-33 {
  flex: 0 0 33%;
}

/* fxFlex="39%" */
.flex-39 {
  flex: 0 0 39%;
}

/* fxFlex="48%" */
.flex-48 {
  flex: 0 0 48%;
}

/* fxFlex="0 0 50"
fxFlex="50%
" */
.flex-50 {
  flex: 0 0 50%;
}

/* fxFlex="66%" */
.flex-66 {
  flex: 0 0 66%;
}

/* fxFlex="0 0 80"
 fxFlex="80"
  */
.flex-80 {
  flex: 0 0 80%;
}

/* fxFlex="85" */
.flex-85 {
  flex: 0 0 85%;
}

/* fxFlex="120px" */
.flex-120px {
  flex: 0 0 120px;
}

/* fxFlex="210px" */
.flex-210px {
  flex: 0 0 210px;
}

.flex-shrink {
  flex-shrink: 1 !important;
}

/*
From "@angular/flex-layout"
Change area to CSS Flexbox up to here
*/

.mat-sort-header-container {
  font:
    500 13px / 16px Roboto,
    'Helvetica Neue',
    sans-serif;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  border-spacing: 0;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: normal;
  text-align: center;
  display: flex;
  align-items: center;
}

.mat-mdc-header-cell.mdc-data-table__header-cell.cdk-header-cell {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.mat-mdc-card.-block {
  display: block;
}

// for side menu
mat-list-item span span.mdc-list-item__primary-text {
  display: flex;
  align-items: center;
}

//for forms
.container {
  form .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled {
    background-color: #fff;
    font-size: 12px;
    padding-bottom: 0;
  }

  div.mat-mdc-form-field-focus-overlay {
    background-color: #fff;
    font-size: 12px;
    padding-bottom: 0;
  }
}

// for modal forms
.mdc-dialog__container {
  form .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled {
    background-color: whitesmoke;
  }
  .mat-mdc-form-field-focus-overlay {
    background-color: whitesmoke;
  }
}

// // modal form
// .mdc-text-field--filled:not(.mdc-text-field--disabled) {
//   background-color: whitesmoke;
// }

// // modar form active
// .mat-mdc-form-field-focus-overlay {
//   background-color: whitesmoke;
// }

// for icon size
button.mdc-button.mat-mdc-unelevated-button mat-icon {
  height: 22px;
  width: 22px;
  font-size: 22px;
}

// for checkbox
mat-checkbox .mat-mdc-checkbox-inner-container .mdc-checkbox {
  padding: 0px !important;
}
mat-checkbox .mdc-form-field .mdc-checkbox {
  padding: 4px 4px;
  .mdc-checkbox__background {
    top: 4px;
    left: 4px;
  }
}

// around login page
.mat-mdc-card-header-text .mat-mdc-card-subtitle {
  margin-top: -8px;
  margin-bottom: 16px;
  font-size: 13px;
}

mat-form-field.mat-mdc-form-field {
  // height: 52px;

  .mat-mdc-form-field-infix {
  }

  mat-label {
    font-size: 12px;
  }
}
